import React from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { PageContainer } from '../components/blocks'
import PageTitle from '../components/PageTitle'
import Layout from '../components/Layout'
import SEO from "../components/SEO";

const PostCard = ({ tags, title, excerpt, date, slug, image }) => (
  <div className="grid md:grid-cols-4 gap-x-5 py-5 border-b border-gray-700">
    <div className="">
      <GatsbyImage
        image={getImage(image)}
        alt=""
        className="rounded h-24 bg-gray-200 w-full"
      />

      <div className="block my-2 opacity-80">
        {tags.map((tag) => (
          <span key={tag} className="md:mx-0 mx-1 first:ml-0 md:block">
            #{tag}
          </span>
        ))}
      </div>
    </div>
    <div className="md:col-span-3">
      <h3 className="text-xl font-bold">{title}</h3>
      <span className="block mt-2 text-sm opacity-80">{date}</span>
      <p className="my-4 text-sm">{excerpt}</p>
      <Link to={`/blog/post${slug}`} className="block text-accent">
        Read more {'->'}
      </Link>
    </div>
  </div>
)

const BlogPage = ({ data }) => (
  <Layout>
    <PageContainer>
      <div className="mx-auto w-full lg:w-1/2">
        <PageTitle title="Blog" />
        <div className="mt-10">
          {data.allMarkdownRemark.edges.map(
            ({ node: { excerpt, frontmatter, fields } }) => {
              const { title, date, tags, coverImage } = frontmatter
              const { slug } = fields
              return (
                <PostCard
                  key={slug}
                  title={title}
                  excerpt={excerpt}
                  date={date}
                  slug={slug}
                  tags={tags}
                  image={coverImage}
                />
              )
            }
          )}
        </div>
      </div>
    </PageContainer>
  </Layout>
)

export default BlogPage

export const Head = () => (
    <SEO title="Blog" pathname="/blog" />
)

export const query = graphql`
  query BlogIndexQuery {
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(posts)/"  }}) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            tags
            date(formatString: "MMMM DD, YYYY")
            coverImage {
              childImageSharp {
                gatsbyImageData(width: 500)
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
